<template>
  <div>
    <v-form ref="redeem_form">
      <v-row class="mb-4">
        <v-col>
          <h2 v-if="isAddRedeem">Add Redeem</h2>
          <h2 v-else>Edit Redeem</h2>
        </v-col>
      </v-row>
      <v-row>
          <v-col>Register date: {{ redeem_data.create_date }}</v-col>
          <v-col>
          </v-col>
        </v-row>
        <v-row>
          <v-col xl="6" lg="6" md="12" sm="12" col="12">
            Redeem ID: {{ redeem_data.id }}
          </v-col> 
          <v-col xl="6" lg="6" md="12" sm="12" col="12">
            User ID: {{ redeem_data.user }}
          </v-col> 
        </v-row>
       
       <v-row>
      <v-col cols="12">
        <data-table-with-pagination
          :headers="redeem_header"
          :dataList="redeem_list"
        ></data-table-with-pagination>
      </v-col>
    </v-row>

        <v-row>
          <v-col>
            <v-btn color="success" :loading="submitLoading" @click="saveRedeemData()">返回</v-btn>
          </v-col>
        </v-row>
    </v-form>
    
    <LoadingDialog :load="showLoadingPage"></LoadingDialog>
    <AlertBox :type="alertBox.type" :text="alertBox.text" :show="alertBox.show"></AlertBox>
  </div>
</template>

<script>
import { Validator, MEDIA_PATH } from '@/global'
import LoadingDialog from '@/components/LoadingDialog'
import AlertBox from '@/components/AlertBox'
import DataTableWithPagination from '@/components/DataTableWithPagination.vue'
import { globalFunc } from '@/global'
import { mapActions } from 'vuex'
import { getData, postData } from '@/api'
import { mdiPlusCircle } from '@mdi/js';
import { mdiMinusCircle } from '@mdi/js';

export default {
  name: 'redeem-management-detail',
  components: {
    LoadingDialog,
    AlertBox,
    DataTableWithPagination,
  },
  data: () => ({
    user_center_id: -1,
    verifiedError: false,
    isAddRedeem: false,
    globalFunc: globalFunc,
    showLoadingPage: false,
    submitLoading: false,
    icon_update: false,
    tab: null,
    icon: require('@/assets/images/images/no_image.png'),
    backup_icon: null,
    icons: {
      mdiPlusCircle, mdiMinusCircle
    },
    alertBox: {
      show: false,
      text: '',
      type: '',
    },
    ticket_header: [
      { text: 'Date', value: 'date' },
      { text: 'Tickets Number', value: 'ticket_id' },
      { text: 'Order Number', value: 'order_id' },
      { text: 'Status', value: 'status' },
    ],
    ticket_list: [
      { date: '2021-01-13', ticket_id: 'TONE006', order_id: 'E000016', status: 'Used' },
      { date: '2021-01-12', ticket_id: 'TONE005', order_id: 'E000016', status: 'Available' },
    ],
    credit_header: [
      { text: 'Date', value: 'date' },
      { text: 'Amount', value: 'credit_amount' },
      { text: 'Type', value: 'credit_type' }
    ],
    credit_list: [],
    redeem_header: [
      { text: 'Image', value: 'icon' },
      { text: 'Name', value: 'name' },
    ],
    redeem_list: [{icon:"",name:"Product1"},{icon:"",name:"Product2"}],
    record_data: [],
    load_record_table: true,
    register_detail_data: [],
    Validator: Validator,
    redeem_data: {
      create_date:'2021-03-10 19:00',
      id:1,
      user:'Jimmy',
    },
    backup_redeem_data: {},
    user_type_list: [
      {
        'item-text': '用戶',
        'item-value': 'user',
      },
      {
        'item-text': '員工',
        'item-value': 'staff',
      },
    ],
    permission_type_list: [
      {
        'item-text': '普通',
        'item-value': 'normal',
      },
      {
        'item-text': '管理員',
        'item-value': 'super_admin',
      },
    ],
    status_list: [
      { 'item-text': '已激活', 'item-value': 'active' },
      { 'item-text': '未激活', 'item-value': 'inactive' },
    ],
    center_list: [],
    center_rules: [],
    center_readOnly: true,
    error: {
      password: false,
    },
  }),
  methods: {
    ...mapActions(['reset']),
    /**
    * Initializing the data
    */
    async initializeData() {
      try {
        let params = new URLSearchParams()
        params.set(
          'get_redeem_by_id',
            JSON.stringify({
              id: parseInt(this.$route.params.id),
            }),
        )

        let result = await getData(params)
        let data = result
        console.log('--- Get redeem data ---')
        console.log(result)

        let temp_data = {
          create_date: globalFunc.getDateStr(data.create_date),
          active: data.active,
          name: data.name,
          icon: data.icon,
          description: data.description
        }

        this.icon = data.icon
        this.backup_icon = data.icon

        // Object.assign(this.redeem_data, temp_data)
        Object.assign(this.backup_redeem_data, temp_data)

      } catch (error) {
        console.log('--- Get redeem data fail ---')
        console.log(error)
      }
    },
    async getRedeemImageFile(file) {
      if (globalFunc.DataValid(file)) {
        const vm = this
        if (file) {
          try {
            let bas4Path = await globalFunc.firstFileToBase64(file)

            let type = file.type.substring(file.type.lastIndexOf('/') + 1)
            let params = new URLSearchParams()
            let send_data = {
              upload_data: bas4Path,
              upload_file_type: type,
            }
            
            params.set('upload_file', JSON.stringify(send_data))

            const config = {
              onUploadProgress: progressEvent => {
                console.log(progressEvent.loaded)
                vm.progress = progressEvent.loaded * 0.75
              },
            }

            let upload_image = await postData(params, config)
            this.icon = MEDIA_PATH + upload_image;
            this.icon_update = true
            vm.uploading = false
            vm.progress = 100
          } catch (error) {
            console.log(error)
            vm.uploading = false
          }
        }
      }
      
    },
    /**
     * Trigger the hidden file input
     * @param {string} - the selector of the file input field
    */
    triggerUploadImage(id) {
      document.getElementById(id).click()
    },
    /**
     * Saving the redeem data to backend
    */
    async saveRedeemData() {
      this.$router.replace({ name: 'redeem-management' })
      // try {
      //   let key = ''
      //   let send_data = {}
    
      //   if (!this.isAddRedeem) {
      //     send_data.id = parseInt(this.$route.params.id)
      //     // send_data.editor = parseInt(this.$route.params.id)

      //     let updated = false
      //     if (this.redeem_data.name != this.backup_redeem_data.name) {
      //       updated = true
      //       send_data.name = this.redeem_data.name
      //     }

      //     if (this.redeem_data.active != this.backup_redeem_data.active) {
      //       updated = true
      //       send_data.active = this.redeem_data.active
      //     }

      //     if (this.redeem_data.description != this.backup_redeem_data.description) {
      //       updated = true
      //       send_data.description = this.redeem_data.description
      //     }

      //     if (this.icon != this.backup_icon) {
      //       updated = true
      //       send_data.icon = this.icon
      //     }

      //     console.log(send_data)
          
      //     if (!updated && !this.icon_update) {
      //       this.alertBox.text = '沒有資料需要更新'
      //       this.alertBox.type = 'error'
      //       this.alertBox.show = true
      //       return
      //     }

      //     key = 'update_redeem'
      //   } else {
      //     send_data.name = this.redeem_data.name
      //     send_data.icon = this.icon
      //     send_data.description = this.redeem_data.description
      //     send_data.active = this.redeem_data.active
      //     key = 'new_redeem'

      //     console.log(send_data)
      //   }

      //   let params = new URLSearchParams()
      //   params.set(key, JSON.stringify(send_data))
      //   let result = await getData(params)
      //   console.log(result)


      //   if (result) {
      //     this.alertBox.text = '儲存成功'
      //     this.alertBox.type = 'success'
      //     this.alertBox.show = true
      //     setTimeout(() => {
      //       this.$router.replace({ name: 'redeem-management' })
      //     }, 1500)
      //   } else {
      //     this.alertBox.text = '沒有資料需要更新'
      //     this.alertBox.type = 'error'
      //     this.alertBox.show = true
      //   }
      // } catch (error) {
      //   console.log(error)
      //   this.alertBox.text = '儲存失敗'
      //   this.alertBox.type = 'error'
      //   this.alertBox.show = true
      // } finally {
      //   setTimeout(() => {
      //     this.submitLoading = false
      //     this.alertBox.show = false
      //   }, 1500)
      // }
    },

    /**
     * kicking out the user
    */
    kickout() {
      console.log('--- Validation Fail ---')
      this.alertBox.text = '登入憑證過期，3秒後重新導向'
      this.alertBox.type = 'error'
      this.alertBox.show = true

      setTimeout(() => {
        this.alertBox.show = false
        let send_data = []
        send_data['method'] = 'resetData'
        this.reset(send_data)
        this.$router.push({ name: 'Login' })
      }, 3000)
    },
  },
  /**
   * Initializing at the beginning
  */
  created() {
    setTimeout(async () => {
      console.log('--- Validation ---')
      if (globalFunc.DataValid(this.$store.state.api.login_data)) {
        try {
          this.isAddRedeem = true
          this.showLoadingPage = true
          setTimeout(() => {
            this.showLoadingPage = false
          }, 1000)

          if (globalFunc.DataValid(this.$route.params.id)) {
            this.isAddRedeem = false
            this.initializeData()
          }
        } catch (error) {
          console.log('--- Check User Permission Fail ---')
          console.log(error)
          this.verifiedError = true
          this.showLoadingPage = false
          this.alertBox.text = '資料取得失敗'
          this.alertBox.type = 'error'
          this.alertBox.show = true

          setTimeout(() => {
            this.alertBox.show = false
            this.$router.replace({ name: 'redeem-management' })
          }, 3000)
        }
      } else {
        this.kickout()
      }
    }, 200)
  },
  watch: {
    /*     'redeem_data.user_type': function (newVal) {
      if (this.isAddRedeem) {
        if (this.center_readOnly) {
          this.center_readOnly = false
          this.center_rules = [Validator.required]
        }
      }
    }, */
  },
}
</script>
<style lang="scss" scoped>
  .icon-img{
    border-radius:50%;
  }
</style>